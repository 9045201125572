export const Data = {
  profile: {
    name: "Gaurav Panta",
    ocupation: "Software Architect | Backend Engineer",
    location: "Dublin, Ireland",
    email: "gp2344@gmail.com",
    telephone: "+353 830293689",
    image: "images/headerimage.jpeg",
  },
  aboutMe: {
    label: "Profile",
    description:
    "Software Engineer, trained to develop computer applications in various technologies and multiple programming languages, applying agile development methodologies, including identification of needs and system designs based on development models.",
  },
  skills: {
    technicalLabel: "Technologies",
    softLabel: "Skills",
    technicalSkills: [
      "Python",
      "Node.js",
      "JavaScript",
      "MongoDB",
      "Postgresql",
      "RabbitMQ",
      "Amazon SQS",
      "SQL",
      "Git",
      "InfluxDB",
      "Redis",
      "Redis Modules (Redisearch / Redis Timeseries)",
      "RUST"
    ],
    softSkills: [
      "Analysis capacity",
      "Problem resolution",
      "Critical thinking",
      "Teamwork",
      "Communication",
      "Proactivity",
      "Creativity",
      "Autodidact",
      "Mentoring",
    ],
  },
  socialMedia: {
    label: "SOCIAL",
    social: [
      {
        label: "LinkedIn",
        name: "linkedin",
        url: "https://www.linkedin.com/in/gauravpanta",
        className: "bxl-linkedin-square",
      },
      {
        label: "Gaurav",
        name: "github",
        url: "https://github.com/idengaurav",
        className: "bxl-github",
      },
      {
        label: "Github",
        name: "github-2",
        url: "https://github.com/gauravpanta",
        className: "bxl-github",
      }
    ],
  },
  experience: {
    works: [
      {
        title: "Sr. Software Engineer",
        period: "Jun. 2019 - Present",
        company: "IDenTV LLC",
        description: [
          "Development and continuous improvement of REST microservices in Python and Node.js(JS/TS).",
          "Development and continuous improvement of Machine Learning microservices in Python.",
          "Algorithmic and Scalability Upgrades to entire video processing backend for un interrupted operation.",
          "CI/CD pipeline with Github, CircleCI and AWS / Self-hosted Kubernetes as infrastructure.",
          "Containerization of CUDA applications with Docker.",
          "Managed Multiple Projects acting as Team Lead."

        ],
      },
      {
        title: "Software Engineer",
        period: "Apr. 2018 - May 2019",
        company: "GrowByData",
        description: [
          "A/B Testing with A/B tasty framework primarily using Javascript.",
          "eCommerce Data Crawling / Website bug fixes.",
          "RTML page layout on yahoo business webistes."
        ],
      },
      {
        title: "Junior Software Engineer",
        period: "Jan. 2018 - Apr 2019",
        company: "Softech Foundation",
        description: [
          "Development of Web Applications in ASP.NET / Laravel.",
          "Created a auto backup module in c# that publishes to clients google drive",
          "Worked on maintaining multiple issues with systems such as Inventory Management System, Information Management System",
        ],
      }
    ],
    academic: [
      {
        career: "Bachelor of Engineering in Software Engineering",
        date: "Nov 2017",
        institution: "Pokhara University",
      },
    ],
    proyects: [
      {
        name: "Asharq News - News platform",
        company: "IDenTV",
        period: "Feb. 2020 - Jan. 2022",
        description: [
          "Created all REST API's / schedulers / microservices responsible for full functioning of Website and Publishing System",
          "Implemented Hybrid recommendation engine for real time news recommendation.",
          "Implemented custom database apis to overcome DynamoDB's character limitation.",
          "Implemented lexical and contextual search systems.",
          "Integration with Juicer for Video / Audio / Image processing."
        ],
      },
      {
        name: "Juicer - Video Inference through Computer Vision",
        company: "IdenTV",
        period: "Nov. 2019 - Present",
        description: [
          "Refactored REST API to a modern architecture upgrading all backend system and workflow.",
          "Improved existing video inference system algorithm and sped up the system by refactoring the internal service architecture",
          "Updated speech to text capability and text search.",
          "Created Alerting System that sends emails and UI notifications via websockets with a rule engine server.",
          "Updated Video Tracking System and increased performance on CPU by 300%",
          "Re-architectured video inference backend to support high scalability and high availability",
          "Added support for HAIVISION SRT decoding for real time processing.",
          "Implemented Real Time Stream Generator through each engine.",
          "Added Kafka Support",
          "Added support for CUDA Hardware acceleration in generating HLS streams, massively increasing real time capability."
        ],
      },
      {
        name: "Video and Audio Recognition System",
        company: "IdenTV",
        period: "Nov. 2019 - Present",
        description: [
          "Created all REST API's.",
          "Refactored legacy Nodejs application to latest Node version with multiple upgrades to developer workflow.",
          "Feature Addition: Automatic Ad Cutter from Live TV feed.",
          "Feature Addition: Timeseries database (InfluxDB) integration for alerts and analytics.",
          "Feature Addition: Audio Landmark Generation and Match System with Redisearch 2.",
          "QA system for analysis of all audio and video match testing.",
          "Re-Architectured entire system and database workflow for maximum effieciency and capability to process realtime in CPU only systems."
        ],
      },
      {
        name: "Video and Image Search",
        company: "IdenTV",
        period: "Jan. 2022 - Present",
        description: [
          "Created all REST API's.",
          "Created Architecture plan and implemented high volume video / image analytical tools.",
          "Created Microservices and data aggregation tools which utilize Computer Vison and Machine Translation Technologies.",
        ],
      },
      {
        name: "Tagging Platform",
        company: "IdenTV",
        period: "Jul. 2021 - Present",
        description: [
          "Created Architecture plan and implemented high volume video / image tagging platform.",
          "Created Microservices which utilize Computer Vison for quick and efficient image / activity tagging.",
          "Feature Addition: Face Recognition and Reverse Embedding Search"
        ],
      }
    ],
  },
};
